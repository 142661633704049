import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import GrandTheatreImage from '../../assets/images/publication-banners/Grand-Theatre.jpg';

export const query = graphql`
  query GrandQuery {
    allGrandCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function GrandTheatre({ data }) {
  const grandNodes = data.allGrandCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/grand/';
  // append the image reference with the path to the image via the static folder
  return (
    <Layout>
      <Helmet>
        <title>Grand Theatre - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Grand Theatre’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Grand Theatre</h1>
            </header>
            <span className="image main">
              <img src={GrandTheatreImage} alt="" />
            </span>
            <p className="box">
              The Grand Theatre Foundation produces quality community theatre,
              educational outreach programs, and cooperative partnerships to
              enrich the community through the performing arts and humanities.
              <br />
              <br />
              The Theatre engages local talent to celebrate our community and
              provides an exceptional theatrical experience for their audiences
              all while committed to presenting productions that will entertain
              and engage their audience in new dynamic ways.
              <br />
              <br />
              The historic South High School, now the South Campus for Salt Lake
              Community College, houses the Grand Theatre in the magnificent
              school auditorium. Over 30,000 people attend the performances each
              season.
            </p>
            <ul className="actions">
              <li>
                <a
                  href="http://grandtheatrecompany.com/"
                  className="button special"
                >
                  <i className="icon fa-globe"> </i>Grand Theatre Website
                </a>
              </li>
            </ul>
            <h2>
              Grand Theatre <SeasonYear /> Season Information
            </h2>

            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses array, and maps/creates a new array */}
              {grandNodes.map((node) => {
                return (
                  <div className="col-4 box" key={node.id}>
                    <img
                      style={{ width: 100 + '%' }}
                      src={imageRef + node.Image}
                    />
                    <h3>{node.SHOW}</h3>
                    <strong>{node.RUNNING_DATES}</strong> <br />
                    <br />
                    <h5>Advertising Information</h5>
                    <section className="box">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              File Ready Deadline: <br />
                              {node.FR}
                            </td>
                          </tr>
                          {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                          <tr>
                            <td>Print Quantity: {node.Quantity}</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
